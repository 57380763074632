@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
}

@layer utilities {
  .no-spinner {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .flex-important {
    display: flex !important;
    justify-content: center;
  }

  .custom {
    border-radius: 0;
    -webkit-appearance: none;
  }

  .no-padding {
    padding: 0 !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
    display: none !important;
  }

  .no-counter::-webkit-inner-spin-button,
  .no-counter::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-counter {
    -moz-appearance: textfield;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-in-out forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-out {
    animation: fadeOut 1s ease-in-out forwards;
  }

  .pulse {
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    width: 20px;
    margin: 3px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background: linear-gradient(
          0deg,
          rgb(0 0 0/50%) 30%,
          #0000 0 70%,
          rgb(0 0 0/100%) 0
        )
        50%/8% 100%,
      linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0)
        50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .loader::before,
  .loader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }
  .loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }

  .select-center {
    -webkit-appearance: none;
    appearance: none;
    text-align-last: center;
  }

  .webkit-none {
    -webkit-appearance: none;
    /-moz-appearance: none;
    appearance: none;
  }
}

.custom-confirm-btn {
  color: #545454;
}

.swiper-button-next,
.swiper-button-prev {
  color: #111111;
  font-size: 1rem;
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.2rem;
}

.swiper-pagination-bullet {
  background: white;
  opacity: 0.7;
}

.swiper-button-next,
.swiper-button-prev {
  top: calc(100% - 22px);
  right: 9px;
  left: auto;
}

.swiper-button-next {
  padding-left: 3px;
}

.swiper-button-prev {
  left: auto;
  right: 55px;
  padding-right: 3px;
}

.swiper-pagination-bullet-active {
  background: white;
  opacity: 1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
